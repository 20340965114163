import React from "react"
import Layout from "../components/layout/layout"
import InquiryForm from "../components/buy-equipment/inquiry-form"
import SEO from "../components/seo"

const ContactUs = () => {
  return (
    <>
      <Layout>
        <SEO title="Contact Us" />

        <div
          className="row column"
        >
          <div className="row">
            <div className="medium-6 columns medium-push-6">
              <h2>Headquarters</h2>
              <p className="mb1">2010 Diamond Hill Road, Woonsocket, RI 02895</p>
              <p className="mb1">
                Phone: <a href="tel:401-526-0055">401-526-0055</a>
              </p>
              
              <p className="mb1">
                E-Mail:{" "}
                <a href="mailto:sales@ssllc.com">sales@ssllc.com</a>
              </p>
              <p>
                Web: <a href="/">www.ssllc.com</a>
              </p>

              <div className="embed-container">
                <iframe
                  frameBorder="0"
                  height="300"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.222408868784!2d-71.47093068432164!3d42.01696097921146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e46966890e9d55%3A0xb5f093fe077c9cee!2s2010+Diamond+Hill+Road%2C+Woonsocket%2C+RI+02895!5e0!3m2!1sen!2sus!4v1492791217473"
                  style={{border:"0"}}
                  width="400"
                ></iframe>
              </div>
              <br/>
              <h2>Additional Locations</h2>
              <p className="mb1">30220 Commerce Court, Murrieta, CA 92563</p>
              
              <div className="embed-container">
                <iframe
                  frameBorder="0"
                  height="300"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.949015509066!2d-117.1340509227737!3d33.55470097334837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80db7dd5f7106f95%3A0x743af80be0d3116e!2s30220%20Commerce%20Ct%2C%20Murrieta%2C%20CA%2092563!5e0!3m2!1sen!2sus!4v1715613626869!5m2!1sen!2sus"
                  style={{border:"0"}}
                  width="400"
                ></iframe>
              </div>
            </div>
            <div className="medium-6 columns medium-pull-6 mt6">
             <InquiryForm title="Contact Us" formSource="Contact Us" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactUs
